.button {
  background-color: transparent;
  cursor: pointer;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: auto;

  span,
  p {
    font-weight: var(--fontWeight500);
  }

  svg {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  p {
    margin: 0;
  }
}

.border-none {
  border: none;
}

.border-solid-light {
  border: 1px solid var(--colorBlack20);
}

.border-dashed-light {
  border: 1px dashed var(--colorBlack20);
  border-radius: 8px;
}

.bg-fill {
  border-radius: 8px;
}

.primary,
.primary-outline {
  border-radius: 8px;
}

.primary {
  color: white;
  height: 64px;
  width: 100%;
  justify-content: center;

  p {
    font-weight: var(--fontWeight500);
    font-size: var(--fontSize20);
  }
}

.primary-light {
  height: 56px;
  width: 100%;
  display: inline-block;
  color: var(--colorBluePrimary);
  background-color: var(--colorBlack10);
}

.primary-outline {
  border: 1px solid var(--colorBlack60);
  padding: 16px 0;
  width: 200px;
  justify-content: center;
  color: var(--colorBlack60);

  svg {
    fill: var(--colorBlack60);
  }
}

.full-width {
  width: 100%;
  display: inline-block;
}

.secondary {
  color: var(--colorBluePrimary);
}

.disabled {
  border: none;
  cursor: default;
  outline: none;
  color: var(--colorBlack50);
}

.focus {
  border-color: var(--colorBlack90);
  color: var(--colorBlack90);

  span,
  p {
    font-weight: var(--fontWeight500);
  }

  svg {
    fill: var(--colorBlack90);
  }
}
