.material-styled-animation {
  --safari-helper1: var(--colorBluePrimary);
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
}

/* Input, Textarea */
.material-styled-animation > input,
.material-styled-animation > textarea {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px; /* Safari */
  border-color: var(--colorBlack30);
  border-top-color: transparent;
  border-radius: 6px;
  padding: 15px 13px 15px;
  width: 100%;
  height: inherit;
  // color: var(--colorBlueSecondary);
  background-color: transparent;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: border 0.2s, box-shadow 0.2s;
}

/* Span */
.material-styled-animation > input + span,
.material-styled-animation > textarea + span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-color: var(--colorBlack30);
  width: 100%;
  max-height: 100%;
  color: var(--colorBlack50);
  font-size: var(--fontSize12);
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Corners */
.material-styled-animation > input + span::before,
.material-styled-animation > input + span::after,
.material-styled-animation > textarea + span::before,
.material-styled-animation > textarea + span::after {
  content: '';
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: var(--colorBlack30);
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.material-styled-animation > input + span::before,
.material-styled-animation > textarea + span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 6px 0;
}

.material-styled-animation > input + span::after,
.material-styled-animation > textarea + span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 6px;
}

/* Hover */
.material-styled-animation:hover > input,
.material-styled-animation:hover > textarea {
  // border-color: var(--colorBlueSecondary);
  border-top-color: transparent;
}

.material-styled-animation:hover > input + span::before,
.material-styled-animation:hover > textarea + span::before,
.material-styled-animation:hover > input + span::after,
.material-styled-animation:hover > textarea + span::after {
  // border-top-color: var(--colorBlueSecondary);
}

.material-styled-animation:hover > input:not(:focus):placeholder-shown,
.material-styled-animation:hover > textarea:not(:focus):placeholder-shown {
  // border-color: var(--colorBlueSecondary);
}

/* Placeholder-shown */
.material-styled-animation > input:not(:focus):placeholder-shown,
.material-styled-animation > textarea:not(:focus):placeholder-shown {
  border-top-color: var(--colorBlack30);
}

.material-styled-animation > input:not(:focus):placeholder-shown + span,
.material-styled-animation > textarea:not(:focus):placeholder-shown + span {
  // font-size: inherit;
  line-height: 68px;
}

.material-styled-animation > input:not(:focus):placeholder-shown + span::before,
.material-styled-animation
  > textarea:not(:focus):placeholder-shown
  + span::before,
.material-styled-animation > input:not(:focus):placeholder-shown + span::after,
.material-styled-animation
  > textarea:not(:focus):placeholder-shown
  + span::after {
  border-top-color: transparent;
}

/* Focus */
.material-styled-animation > input:focus,
.material-styled-animation > textarea:focus {
  border-color: var(--colorBluePrimary);
  border-top-color: transparent;
  box-shadow: inset 1px 0 var(--safari-helper1),
    inset -1px 0 var(--safari-helper1), inset 0 -1px var(--safari-helper1);
  outline: none;
}

.material-styled-animation > input:focus + span,
.material-styled-animation > textarea:focus + span {
  color: var(--colorBluePrimary);
}

.material-styled-animation > input:focus + span::before,
.material-styled-animation > input:focus + span::after,
.material-styled-animation > textarea:focus + span::before,
.material-styled-animation > textarea:focus + span::after {
  border-top-color: var(--safari-helper1) !important;
  box-shadow: inset 0 1px var(--safari-helper1);
}

/* Disabled */
.material-styled-animation > input:disabled,
.material-styled-animation > input:disabled + span,
.material-styled-animation > textarea:disabled,
.material-styled-animation > textarea:disabled + span {
  border-color: rgba(var(--onsurface-rgb, 0, 0, 0), 0.38) !important;
  border-top-color: transparent !important;
  color: rgba(var(--onsurface-rgb, 0, 0, 0), 0.38);
  pointer-events: none;
}

.material-styled-animation > input:disabled + span::before,
.material-styled-animation > input:disabled + span::after,
.material-styled-animation > textarea:disabled + span::before,
.material-styled-animation > textarea:disabled + span::after {
  border-top-color: rgba(var(--onsurface-rgb, 0, 0, 0), 0.38) !important;
}

.material-styled-animation > input:disabled:placeholder-shown,
.material-styled-animation > input:disabled:placeholder-shown + span,
.material-styled-animation > textarea:disabled:placeholder-shown,
.material-styled-animation > textarea:disabled:placeholder-shown + span {
  border-top-color: rgba(var(--onsurface-rgb, 0, 0, 0), 0.38) !important;
}

.material-styled-animation > input:disabled:placeholder-shown + span::before,
.material-styled-animation > input:disabled:placeholder-shown + span::after,
.material-styled-animation > textarea:disabled:placeholder-shown + span::before,
.material-styled-animation > textarea:disabled:placeholder-shown + span::after {
  border-top-color: transparent !important;
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .material-styled-animation > input,
    .material-styled-animation > input + span,
    .material-styled-animation > textarea,
    .material-styled-animation > textarea + span,
    .material-styled-animation > input + span::before,
    .material-styled-animation > input + span::after,
    .material-styled-animation > textarea + span::before,
    .material-styled-animation > textarea + span::after {
      transition-duration: 0.1s;
    }
  }
}

.borderless {
  &.material-styled-animation {
    > input {
      border: none;
    }

    > input:focus,
    > input + span::before,
    > input + span::after {
      border: none;
      box-shadow: none;
    }
  }
}

.error-styles {
  > input,
  > input + span {
    color: var(--colorRed);
  }
}
