/*

These are styles that are used to force the mobile view to behave.

As compoennts are refactored to handle responsiveness better
and use styled components, this will eventually be removed

*/

.mobile--stacked-rows-group {
  border-bottom: 1px solid var(--colorBlack20);

  &.no-bottom-border {
    border-bottom: none;
  }

  & > {
    .row.is-mobile {
      border-top: none !important;
      border-bottom: none !important;

      &.is-label {
        padding-bottom: 0;
      }

      .editable-field-wrapper {
        margin: 0;
      }
    }
  }
}

input.with-mobile-bottom-border {
  border-bottom: 1px solid var(--colorBlack30) !important;
}
