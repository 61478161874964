@import-normalize;

/**
 * The normalize line-height is 1.15. Resetting it to 1.5
 * seems to maintain the original line-height that has been
 * in use for this application.
 */
html {
  line-height: 1.5;
}

@font-face {
  font-family: 'Heebo';
  src: url(/fonts/Heebo-Light.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Heebo';
  src: url(/fonts/Heebo-Regular.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Heebo';
  src: url(/fonts/Heebo-Medium.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Heebo';
  src: url(/fonts/Heebo-Bold.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

:root {
  --baseFontFamily: 'Heebo', sans-serif;
  --grid-gap: 10px;

  // neutral colors
  --colorWhite: #ffffff;
  --colorBlack05: #fafafa;
  --colorBlack10: #f5f8fc;
  --colorBlack20: #e1e4e8;
  --colorBlack30: #c9cdd1;
  --colorBlack40: #b1b5ba;
  --colorBlack50: #999da2;
  --colorBlack60: #6b7075;
  --colorBlack70: #53585d;
  --colorBlack80: #3b4147;
  --colorBlack90: #23292f;
  --colorBlack100: #171c21;

  // shades of blue
  --colorBluePrimary: #0f72e5;
  --colorBlueSecondary: #0a93eb;
  --colorBlueTertiary: #1db4ff;
  --colorBlueLighten: #d8eefc;

  // color pairs
  --colorGreen: #2bcb96;
  --colorGreenLighten: #d5f0e9;

  --colorRed: #f23548;
  --colorRedLighten: #fddfe2;

  --colorOrange: #fe843e;
  --colorOrangeLighten: #fbebe2;

  --colorYellow: #fed74c;
  --colorYellowLighten: #fbf5e3;

  // colors that will never be used
  --colorMagenta: #f52a79;
  --colorPurple: #691fdd;
  --colorViolet: #7357ff;

  --fontWeight400: 400; // regular
  --fontWeight500: 500; // medium
  --fontWeight700: 700; // bold
  --fontWeight800: 800; // extra-bold

  // TODO: refactor typography naming conventions
  // (xl -> xs)
  // this will do for now
  --fontSize40: 4rem;
  --fontSize28: 2.8rem;
  --fontSize24: 2.4rem;
  --fontSize22: 2.2rem;
  --fontSize20: 2rem;
  --fontSize18: 1.8rem;
  --fontSize16: 1.6rem;
  --fontSize14: 1.4rem;
  --fontSize12: 1.2rem;

  --peachyShadow: 0px 4px 32px rgba(0, 0, 0, 0.16);
}

html {
  // have a better indicator of font-sizing
  // 1rem = 10px
  // usage: 1.6rem = 16px
  font-size: 62.5%;

  * {
    font-family: var(--baseFontFamily), monospace;
  }
}

body {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > * {
    box-sizing: border-box;
  }
}

#badgeRoot {
  position: fixed;
  bottom: 0;
  right: 0;
}

h1 {
  font-size: var(--fontSize40);
  line-height: 1.2;
}

h2 {
  font-weight: var(--fontWeight400);
}

h3 {
  font-size: var(--fontSize24);
  font-weight: var(--fontWeight400);
  line-height: 1.5;
  margin: 0;
}

span,
p {
  line-height: 1.5;
}

b {
  font-weight: var(--fontWeight500);
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  padding: 0;
}

input::placeholder {
  color: var(--colorBlack30);
}

/* Focus visible CSS */

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

@import 'core/styles/mobile-styles.scss';
